.m-info-boxes {
  margin-bottom: -30px;

  &__list {
    @include list-unstyled;
    margin: 0 floor($grid-gutter-width / -3);
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: $screen-lg-min) {
      flex-wrap: nowrap;
    }

    &--box {
      margin: 0;

      @media screen and (min-width: $grid-float-breakpoint) {
        margin-right: floor($grid-gutter-width / -2);
        margin-left: floor($grid-gutter-width / -2);
      }
    }
  }

  &__item {
    display: flex;
    margin-bottom: 30px;
    padding-right: floor($grid-gutter-width / 3);
    padding-left: floor($grid-gutter-width / 3);
    text-align: left;
    flex: 1 0 300px;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex-basis: 40%;

      &--i3 {
        flex-basis: 33%;
      }
    }

    @media screen and (min-width: $screen-lg-min) {
      flex-basis: 25%;

      &--without-icon {
        max-width: 250px;
        flex-basis: auto;
      }
    }

    &--box {
      margin: 0 floor($grid-gutter-width / 2) $grid-gutter-width;
      padding: $grid-gutter-width;
      max-width: none;
      text-align: center;
      flex: 1 1 100%;

      @media screen and (min-width: $screen-xs-min) {
        flex-basis: 40%;
      }

      @media screen and (min-width: $screen-lg-min) {
        flex-basis: 0;
      }

      &.m-info-boxes__item--i3 {
        @media screen and (min-width: $screen-xs-min) {
          flex-basis: 100%;
        }

        @media screen and (min-width: $screen-sm-min) {
          flex-basis: 0;
        }
      }
    }
  }

  &__icon {
    height: 90px;
    margin-right: 29px;

    //.icon-shopping-cart {
    //  font-size: 74px;
    //  line-height: 0;
    //
    //  &:before {
    //    margin: 0;
    //    padding: 0;
    //    width: auto;
    //  }
    //}

    &--box {
      width: 80px;
      height: 80px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      > * {
        transform: scale(.7);
      }
    }

    &--top {
      margin: 0 auto 29px;
    }

    &:not(.m-info-boxes__icon--top) {
      @media screen and (max-width: $screen-lg-max) {
        display: none;
      }
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__title {
    margin: 0 0 20px;
    font-size: 1.25rem;
    text-transform: uppercase;
  }

  &__description {
    margin-bottom: 0;
  }

  &__action {
    margin-bottom: 0;
  }

  .opening-hours__h-hours {
    display: none;
  }
}
