.m-page-subheader {
  margin: 0 0 28px;
  color: inherit;

  @media screen and (min-width: $grid-float-breakpoint) {
    margin-bottom: 49px;
  }

  &__heading {
    margin: 0;
    color: inherit;
  }

  &__sup-title {
    @include font-smoothing;
    display: inline-block;
    margin-bottom: 5px;
    font-size: 1.875rem;
    font-weight: $font-weight-base;

    &:empty {
      margin: 0;
      display: inline;
    }

    .m-page-subheader--small & {
      font-size: 1.5rem;
    }
  }

  &__title {
    margin: 0;
    display: block;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: 2rem;

    @media screen and (min-width: $grid-float-breakpoint) {
      font-size: 2.5rem;
    }

    &:after {
      @include line-ornament(50px, 3px, 0.25em 0 0);
    }

    .m-page-subheader--small & {
      font-size: 1.875rem;
    }
  }

  &__description {
    margin: 25px 0 0;
    font-size: 1rem;

    &:empty {
      margin: 0;
      display: inline;
    }
  }

  &--center {
    text-align: center;

    .m-page-subheader__title {
      &:after {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  &--light {
    color: #fff;

    .m-page-subheader__sup-title {
      color: inherit;
    }

    .m-page-subheader__title {
      color: inherit;

      &:after {
        background-color: #fff;
      }
    }
  }

  &--mb-gutter {
    margin-bottom: ceil($grid-gutter-height / 2);
  }

  &--small {
    margin-bottom: ceil($grid-gutter-height / 2);
  }
}

// Add compatibility with shared modules (apply New Vision header style)
.content-header {
  @extend .m-page-subheader;
  @extend .m-page-subheader--center;

  .h1,
  .h2 {
    @extend .m-page-subheader__title;
  }
}
