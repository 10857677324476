.m-section {
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (min-width: $grid-float-breakpoint) {
    padding-top: 130px;
    padding-bottom: 130px;

    &--base-y-padding {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &--small {
    padding-top: $base-spacing;
    padding-bottom: $base-spacing;
  }

  &--top {
    padding-bottom: 0;
  }

  &--bottom {
    padding-top: 0;
  }
}
