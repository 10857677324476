.m-special-box {
  position: relative;
  overflow: hidden;

  &__ribbon {
    &,
    &.m-heading {
      @include position(absolute, 35px null null -75px);
      width: 430px;
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: var(--color-primary);
      color: var(--color-text-on-primary-bg);
      font-size: 1.125rem;
      text-align: center;
      transform: rotate(-23deg);
      z-index: $zindex-ui;

      @media screen and (min-width: $grid-float-breakpoint) {
        top: 75px;
        left: -65px;
        width: 330px;
        transform: rotate(-45deg);
      }

      @media screen and (min-width: $screen-lg-min) {
        right: -65px;
        left: auto;
        transform: rotate(45deg);
      }
    }
  }

  &__item {
    @media screen and (min-width: $grid-float-breakpoint) {
      display: table;
    }
  }

  &__picture,
  &__item-content {
    @media screen and (min-width: $grid-float-breakpoint) {
      display: table-cell;
      width: 50%;
    }
  }

  &__item-content {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }

  &__picture {
    margin-bottom: 30px;

    @media screen and (min-width: $grid-float-breakpoint) {
      @include position(absolute, $grid-gutter-height null $grid-gutter-height 0);
      display: table-cell;
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__item-img {
    @include center-block;

    @media screen and (min-width: $grid-float-breakpoint) {
      @include u-center;
      max-height: 100%;
      width: auto;

      @supports (object-fit: contain) {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
