.m-breadcrumb {
  &__list {
    @include list-unstyled;
    margin-bottom: 0;

    > li {
      display: inline-block;

      + li {
        &:before {
          padding: 0 5px;
          content: "/\00a0";
        }
      }
    }
  }
}
