.m-list-vertical {
  @include list-unstyled;
  margin: 0;

  &__li {
    margin-bottom: $base-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    display: flex;
  }

  &__icon-i {
    min-width: 16px;
    margin-right: ($grid-gutter-width / 2)
  }
}
