.m-top-bar {
  border-bottom: 1px solid $separator-color-light;
  font-size: 0.875rem;
  text-transform: uppercase;

  @media screen and (max-width: $grid-float-breakpoint-max) {
    display: none;
  }

  &--brand {
    border-bottom: 1px solid $separator-color;
  }

  .m-language-select {
    display: flex;
    min-height: 50px;
    align-items: center;
  }
}
