.m-steps {
  text-align: center;

  &__list {
    @include list-unstyled;
    margin: 0 floor($grid-gutter-width / -2) ($base-spacing / -1);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @media screen and (min-width: $screen-md-min) {
      flex-wrap: nowrap;
    }
  }

  &__list-item {
    position: relative;
    margin: 0 auto $base-spacing;
    padding: 0 floor($grid-gutter-width / 2);
    flex: 1 1 280px;
  }

  &__item-separator {
    display: none;
    width: 100px;
    opacity: 0.5;

    svg {
      max-width: 100%;
    }

    @media screen and (min-width: $screen-md-min) {
      margin-top: 40px;
      display: block;
    }

    @media screen and (min-width: $screen-lg-min) {
      width: auto;
    }
  }

  &__media {
    position: relative;
    margin: 0 auto 38px;
    width: 130px;
    height: 130px;
    text-align: center;
    border: 2px solid var(--color-separator);
    border-radius: 50%;
  }

  &__icon {
    @include u-center;
  }

  &__icon-badge {
    @include position(absolute, 0 null null 0);
    width: 40px;
    height: 40px;
    color: var(--color-text-on-primary-bg);
    line-height: 40px;
    font-size: 1.25rem;
    text-align: center;
    border-radius: 50%;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 1.25rem;
  }
}
