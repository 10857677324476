.m-footer {
  &__content {
    padding-top: 70px;
    padding-bottom: (70px - $base-spacing);
  }

  &__cols {
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    margin-bottom: $base-spacing;
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 300px;
  }

  &__heading {
    margin-bottom: $base-spacing;

    &:after {
      @include line-ornament(30px, 3px, 15px 0 0);
    }

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__app-badges {
    margin-bottom: 0;
    margin-left: -10px;
    display: flex;

    &--center {
      justify-content: center;
    }
  }

  &__info {
    border-top: 1px solid $separator-color-light;
    padding-top: 25px;
    padding-bottom: 25px;

    &--tight {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &__logo-img {
    max-height: 150px;
  }

  &--simple {
    .m-footer__col {
      @media screen and (min-width: $screen-lg-min) {
        flex-basis: (100% / 4);

        &--logo {
          max-width: 25%;
          flex-basis: auto;
        }
      }
    }
  }
}
