.m-featured {
  &__list {
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);

    &--fancy {
      padding-left: 0;

      @media (min-width: $screen-sm-min) {
        word-wrap: break-word; // simple fallback for `hyphens`
        hyphens: auto;
      }

      @media (min-width: $screen-xlg-min) {
        &:not(.splide__list) {
          margin-right: -100px;
        }
      }
    }

    &:not(.splide__list) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__list-item {
    padding: 0 floor($grid-gutter-width / 2) 60px;
    display: flex;
    outline: none;

    .m-featured:not(.splide) & {
      flex: 1 1 335px;
    }

    @at-root .m-featured__list--fancy & {
      min-width: 200px;

      .m-featured__list:not(.splide__list) & {
        flex: 1 1 33%;
      }
    }

    @at-root .m-featured__list--fancy:not(.splide__list) & {
      max-width: 360px;

      @media (min-width: $screen-sm-min) {
        max-width: 300px;
      }
    }
  }

  &__item {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 100px;
    padding: 0 40px 40px;
    border: 1px solid;
    flex-grow: 1;
    text-align: center;
    transition: box-shadow $base-duration;

    &:hover,
    &:focus {
      @include new-vision-box-shadow;
      outline: none;
    }

    @at-root .m-featured__list--fancy & {
      margin-top: 0;
      padding: $grid-gutter-width;
      text-align: left;
    }
  }

  &__media {
    margin-bottom: 30px;

    @at-root .m-featured__list--fancy & {
      margin: ceil($grid-gutter-width / -1) ceil($grid-gutter-width / -1) $grid-gutter-width;
    }
  }

  &__media-img {
    margin: -75px auto 0;
    width: 150px;
    border-radius: 50%;

    @media screen and (min-width: $screen-xs-min) {
      margin-top: -100px;
      width: 200px;
    }

    @at-root .m-featured__list--fancy & {
      margin: 0;
      border-radius: 0;
      width: 100%;
    }
  }

  &__title {
    margin: 0 0 15px;
    font-size: 1.5rem;
    text-transform: uppercase;

    @at-root .m-featured__list--fancy & {
      font-size: 1.25rem;
    }
  }

  &__description {
    margin-bottom: $base-spacing;
    flex-grow: 1;
  }

  &__action {
    margin-bottom: 0;

    .btn {
      @at-root .m-featured__list--fancy & {
        padding: 0;
      }
    }
  }
}
