.m-header {
  position: relative;

  // `m-header__container` is similar to `container--xlg` but differs in laptops viewport - it provides more space for middle column in order to fit all navigation links in one line (according with the guidelines from Dropbox Paper).
  &__container {
    @include container-fixed;

    @media (min-width: $screen-sm-min) {
      width: $container-sm;
    }

    @media (min-width: $screen-md-min) {
      width: $container-md;
    }

    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      width: 100%;
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;

      // IE 10 and IE 11 hack
      @media screen and (-ms-high-contrast: none) {
        // Fix for weird IE 11 bug which causes even weirder flexbox bugs for some child elements in this viewport when width is 100% (e.g. wrong `m-header__main` height caused by wrong computed logo size in case of large logo).
        // This workaround has to be limited just for IE because in some other browsers it can cause horizontal scrollbar (e.g. Chrome 70 on Windows 7).
        width: 100vw;
      }
    }

    @media screen and (min-width: $screen-xlg-min) {
      width: $container-xlg;
    }

    @at-root .m-header__main--fancy-logo & {
      width: 100%;
    }
  }

  &__main {
    .m-header--home & {
      padding-top: 5px;
      padding-bottom: 5px;

      @media screen and (min-width: $grid-float-breakpoint) and (min-height: $screen-sm-min) {
        padding-top: 34px;
        padding-bottom: 34px;
      }
    }

    &--fancy-top {
      margin-bottom: 50px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__navbar {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;

    &.is-affixed {
      @include position(fixed, 0 0 null 0);
      @include new-vision-box-shadow;
      animation: fadeIn $base-duration both;
      z-index: $zindex-navbar-fixed;

      .m-header__logo-img {
        max-height: 60px;
      }
    }

    &,
    &.is-affixed {
      @media screen and (min-width: $grid-float-breakpoint) {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    @at-root .m-header__main--fancy-top & {
      &:after {
        position: absolute;
        left: 50%;
        top: 100%;
        width: 0;
        height: 0;
        border-left: 40px solid transparent;
        border-right: 40px solid transparent;
        border-top: 15px solid #fff; // color can be changed by the user
        transform: translateX(-50%);
        content: "";

        @media screen and (min-width: $grid-float-breakpoint) {
          border-left-width: 70px;
          border-right-width: 70px;
          border-top-width: 35px;
        }
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__hero {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
  }

  &__logo {
    display: block;
    max-width: 40vw;

    &--gfb-hidden { // gfb = $grid-float-breakpoint
      @media (min-width: $grid-float-breakpoint) {
        display: none;
      }
    }

    &--affix-visible {
      display: none;

      @at-root .is-affixed & {
        display: block;
      }
    }
  }

  &__logo-img {
    max-height: 80px;

    @media screen and (min-width: $screen-sm-min) {
      max-height: 120px;
    }

    :where(.m-header--home) & {
      @media screen and (min-width: $screen-md-min) {
        max-height: none;
      }
    }
  }

  &--full-coverage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  // Required for legacy browsers in order to simulate object-fit behavior and support long dropdowns
  &__cover-wrapper {
    @include position(absolute, 0 0 0 0);
    overflow: hidden;
  }

  &__cover {
    @include position(absolute, 0 0 0 0);
    opacity: 0.3;
    min-width: 100%;
    min-height: 100%;
    max-width: none;

    @supports (object-fit: cover) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    &--zoom {
      animation: smoothZoom 30s cubic-bezier(.1, .5, .4, .3) both;
    }
  }
}
