$m-zindex-background: $zindex-modal-background !default;
$m-backdrop-opacity: $modal-backdrop-opacity !default;
$m-backdrop-bg: $modal-backdrop-bg !default;

.m-backdrop {
  display: none;

  &.is-active {
    @include position(fixed, 0 0 0 0);
    display: block;
    z-index: $m-zindex-background;
    background-color: $m-backdrop-bg;
    opacity: $m-backdrop-opacity;
  }
}
