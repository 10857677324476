.m-flex-group {
  margin-left: floor($grid-gutter-width / -2);
  margin-right: ceil($grid-gutter-width / -2);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__item {
    padding-right: ceil($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);

    &--aside {
      @media screen and (min-width: $screen-sm-min) {
        flex-basis: 290px;
      }
    }

    &--main {
      text-align: center;
      flex-grow: 1;
    }

    &--nav-toggle {
      flex-basis: 100px;
      color: inherit;
      text-align: right;

      @media screen and (min-width: $screen-md-min) {
        display: none;
      }
    }
  }
}
