.m-bubbles {
  &__list {
    @include list-unstyled;
    margin-bottom: (($base-spacing + 10px) / -1);
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  &__bubble {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: ($base-spacing + 10px);
    width: 130px;
    height: 130px;
    font-size: 2.5rem;
    line-height: 130px;
    border-radius: 50%;
    border: 2px solid $separator-color;
    box-shadow: 0 0 0 10px #f8f8f8;

    &--fa {
      width: 100px;
      height: 100px;
      font-size: 1.875rem;
      line-height: 92px;
      box-shadow: none;
    }

    &--box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: floor($base-spacing / 2);
      min-width: 220px;
      height: 100px;
      color: #000;
      background-color: rgba(#fff, .8);
      border-radius: $border-radius-base;
      font-size: 1.75rem;
      box-shadow: none;
      line-height: revert;

      @media (min-width: $screen-sm-min) {
        margin-bottom: $base-spacing;
        font-size: 3.25rem;
      }
    }

    &--circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      margin-bottom: floor($base-spacing / 2);
      color: #fff;
      background-color: rgba(#000, .55);
      border-radius: 50%;
      font-size: 1.75rem;
      box-shadow: none;
      line-height: revert;

      @media (min-width: $screen-sm-min) {
        width: 180px;
        height: 180px;
        margin-bottom: $base-spacing;
        font-size: 3.25rem;
      }
    }
  }

  &__hexagon {
    position: relative;
    display: flex;
    width: 220px;
    margin: 0 auto floor($base-spacing / 2);
    font-size: 3.25rem;
  }

  &__hexagon-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__item {
    margin-bottom: ($base-spacing + 10px);
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 250px;

    @media screen and (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
      flex-basis: 40%;
    }
  }

  &__title {
    margin: 0 0 12px;
    font-size: 1.25rem;

    &--box,
    &--hexagon,
    &--circle {
      font-weight: $font-weight-bold;
    }
  }
}
