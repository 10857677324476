.m-hero {
  position: static;

  &__header {
    margin: 0 0 20px;
    font-size: 2rem;
    color: inherit;

    .m-hero:not(.m-hero--subpage) & {
      @media screen and (min-width: $screen-sm-min) {
        margin-bottom: 49px;
      }
    }

    @media screen and (min-width: $screen-sm-min) {
      font-size: 3.5rem;
    }

    @media screen and (min-width: $screen-md-min) {
      font-size: 5rem;
    }

    &--slim {
      @media screen and (min-width: $screen-sm-min) {
        font-size: 3.75rem;
      }

      .m-hero__title {
        &:after {
          display: none;
        }
      }
    }
  }

  &__sup-title {
    @include font-smoothing;
    font-size: 1.875rem;
    font-weight: $font-weight-base;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 2.75rem;
    }

    @media screen and (min-width: $screen-md-min) {
      font-size: 3.75rem;
    }
  }

  &__title {
    margin: 3px 0;
    display: block;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: inherit;
    color: inherit;

    &:after {
      @include line-ornament(50px, 4px, 0.15em 0 0) {
        @media screen and (min-width: $grid-float-breakpoint) {
          margin-right: auto;
          margin-left: auto;
          width: 80px;
        }
      }
    }
  }

  &__description {
    margin-bottom: 30px;
    font-size: 1rem;

    .m-hero:not(.m-hero--subpage) & {
      @media screen and (min-width: $grid-float-breakpoint) {
        margin-bottom: 49px;
      }
    }
  }

  &__list {
    @include clearfix;
    margin-bottom: 70px !important; // override Splide reset
  }

  &__content {
    flex-basis: 100%;
    outline: none;

    // Hide visibility of inactive slides for the time of animation transition (without it there is a bug in some variants of horizontal entrances, e.g. `fadeInLeft`).
    &:not(:first-child) { // Alternative way to determine initial slide could be `js-slider__animation-fix` but that would add a dependency, so it's simpler to assume the first one, which is currently always the case.
      .splide:not(.is-initialized) & {
        visibility: hidden;
      }
    }
  }

  &__action {
    margin-bottom: 15px;
  }

  &__indicators {
    @include position(absolute, null 0 $grid-gutter-height 0);
    text-align: center;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    text-align: center;
  }

  &__row {
    display: flex;
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);
    align-items: center;
  }

  &__col {
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 50%;

    &--media {
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }

  &__col-inner {
    &--media-next {
      @media screen and (min-width: $screen-md-min) {
        margin-left: auto;
        max-width: 600px;
      }
    }
  }

  &__logo-wrapper {
    text-align: center;
  }

  &__logo {
    display: inline-block;
  }

  &__logo-img {
    .m-header:not(.m-header--home) & {
      @media screen and (min-width: $screen-md-min) {
        max-height: 220px;
      }
    }
  }

  &--subpage {
    text-align: center;

    .l-checkout & {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }
  }

  &--media {
    text-align: left;

    .m-hero__title {
      &:after {
        margin-left: 0;
      }

      @media screen and (min-width: $screen-md-min) {
        font-size: 3.75rem;
      }
    }
  }
}
