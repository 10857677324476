.m-info-box {
  display: flex;
  margin-bottom: -30px;
  margin-right: floor($grid-gutter-width / -2);
  margin-left: floor($grid-gutter-width / -2);

  @media screen and (max-width: $screen-sm-max) {
    flex-wrap: wrap;
  }

  &__col {
    margin-bottom: 30px;
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 320px;

    &--media {
      @media screen and (min-width: $screen-xs-min) {
        padding-right: floor(100px + ($grid-gutter-width / 2));
      }
    }

    &--media-last {
      @media screen and (min-width: $screen-xs-min) {
        padding-left: floor(100px + ($grid-gutter-width / 2));
        order: 10;
      }
    }

    // Set the same padding on all sides in order to display element in different situations and with different backgrounds.
    &--equal-padding {
      padding: $grid-gutter-width;

      @media (min-width: $screen-sm-min) {
        padding: $grid-gutter-width;
      }

      @media (min-width: $screen-md-min) {
        padding: $base-spacing-large;
      }
    }

    // Variant with large background image on the side.
    &--cover-aside {
      margin-right: $grid-gutter-width;
      padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width ceil($grid-gutter-width / 2);

      @media (min-width: $screen-sm-min) {
        margin-right: 0;
        padding-top: $base-spacing-large;
        padding-right: $base-spacing-large;
        padding-bottom: $base-spacing-large;
        max-width: 85%;
      }

      @media (min-width: $screen-md-min) {
        max-width: 55%;
      }

      // Make sure `equal-padding` modifier has the highest priority.
      &.m-info-box__col--equal-padding {
        @extend .m-info-box__col--equal-padding;
      }
    }
  }

  &__image {
    width: 40%; // simple fallback
    width: 40vw;

    @media screen and (min-width: $grid-float-breakpoint) {
      width: 270px;
    }

    @media screen and (min-width: $screen-lg-min) {
      width: auto;
    }

    &--featured {
      @include new-vision-box-shadow;
      position: relative;
      margin-top: -100px;
      margin-left: 30%;
      border: 4px solid #fff;

      @media screen and (min-width: $screen-lg-min) {
        width: 370px;
        margin-top: -190px;
        margin-left: 100px;
      }
    }
  }

  &__action {
    margin: 0;
  }

  &__content {
    &--frame {
      position: relative;
      margin-bottom: 26px;
      padding-top: floor($grid-gutter-width / 2);
      padding-right: floor($grid-gutter-width / 2);
      padding-left: floor($grid-gutter-width / 2);
      text-align: center;
      border: 2px solid currentColor;

      @media screen and (min-width: $grid-float-breakpoint) {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
      }

      .m-info-box__action {
        margin-bottom: -26px;
      }
    }
  }
}
