$m-menu-teaser-featured-image-breakpoint: 1200px !default;
$m-menu-teaser-featured-image-breakpoint-max: $m-menu-teaser-featured-image-breakpoint - 1;

.m-menu-teaser {
  &__list-wrapper {
    overflow: hidden;
  }

  &__list {
    @include list-unstyled;
    margin-top: -25px;
    margin-bottom: 15px;

    @media screen and (min-width: $grid-float-breakpoint) {
      column-count: 2;
      column-gap: 50px;
    }

    @media screen and (min-width: $screen-lg-min) {
      column-gap: 170px;
    }

    &--single {
      column-count: 1;
    }

    &--featured-image {
      @media (min-width: $m-menu-teaser-featured-image-breakpoint) {
        display: flex;
        margin-bottom: 29px;
        column-gap: 50px;
        column-count: auto;
      }
    }
  }

  &__list-item {
    padding-top: 25px;
    margin-bottom: 25px;
    break-inside: avoid;
    border-top: 1px solid $separator-color;
  }

  &__list-col {
    break-inside: avoid;

    @media (max-width: $m-menu-teaser-featured-image-breakpoint-max) {
      flex: 1 0 0;
    }

    // 1. Required for object fit fallback (see more in `@mixin object-fit-fallback` definition).
    &--featured-image {
      position: relative; // [1]
      overflow: hidden; // [1]
      margin-right: $grid-gutter-width;
      margin-left: $grid-gutter-width;
      min-width: 310px;
      flex-grow: 0;

      @media (max-width: $m-menu-teaser-featured-image-breakpoint-max) {
        display: none;
      }
    }
  }

  &__featured-image {
    @media screen and (min-width: $m-menu-teaser-featured-image-breakpoint) {
      @include object-fit;
      position: absolute; // prevent list resizing in the case of small number of list items and a high image (TODO: it causes bug in edit mode due relative wrapper)
      color: #000;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    margin: 0 0 10px;
    font-size: 1.5rem;
    line-height: 1.4;
    flex: 1 1 auto;

    &--price {
      margin-left: floor($grid-gutter-width / 2);
      flex-basis: 150px;
      text-align: right;
    }
  }

  &__description {
    margin: 0 0 29px;
  }

  &__action {
    margin-bottom: 0;
    text-align: center;
  }
}
