.m-list-separated {
  @include list-unstyled;
  margin: 0;

  &__item {
    display: inline-block;
    border-right: 1px solid $separator-color-light;

    &:first-child {
      border-left: 1px solid $separator-color-light;
    }
  }

  &__link {
    display: block;
    padding: 13px;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &--tight {
    .m-list-separated__item {
      border: none;
    }
    .m-list-separated__link {
      padding: 7px;
    }
  }

  &--brand {
    .m-list-separated {
      &__item {
        border-right: 1px solid $separator-color;

        &:first-child {
          border-left: 1px solid $separator-color;
        }
      }
    }
  }
}
