.m-boxes {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;

  @media screen and (min-width: $grid-float-breakpoint) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__list {
    margin-bottom: ($grid-gutter-height / -1);
    display: flex;
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);
    flex-wrap: wrap;
  }

  &__list-item {
    position: relative;
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 335px;

    &:before {
      @include position(absolute, 0 floor($grid-gutter-width / 2) $grid-gutter-height floor($grid-gutter-width / 2));
      border: 1px solid currentColor;
      opacity: 0.2; // support different color variants
      content: "";
    }
  }

  &__item {
    position: relative; // make inner elements accessible (required next to the `m-boxes__item:before`)
    margin-bottom: $grid-gutter-height;
    padding: 40px $grid-gutter-width;
  }

  &__title {
    margin: 0 0 20px;
    font-size: 1.25rem;
    text-transform: uppercase;

    &--rich {
      letter-spacing: 0.15em;

      &:after {
        @include line-ornament(100%, 2px, 20px 0 0);
      }
    }
  }

  &__description {
    margin-bottom: 20px;

    &--rich {
      font-size: 1rem;
    }
  }

  &__slogan {
    margin: 0;
    font-size: 1rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
}
