.m-overlay {
  position: relative;
  display: block;

  &__item {
    display: block;
  }

  &__content {
    @include position(absolute, 0 0 0 0);
    margin: 0;
    opacity: 0;
    transition: opacity $base-duration;
  }

  &__icon {
    &,
    &:before {
      @include u-center;
      margin: 0;
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 1.125rem;
      color: #fff;
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }

  &.is-active,
  &:hover,
  &:focus {
    .m-overlay__content {
      opacity: 1;
    }
  }
}
