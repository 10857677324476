// Checkout module doesn't follow BEM guidelines because of the limited control over markup and shared code for all themes.

.m-checkout {
  &__cols {
    display: flex;
    flex-flow: column nowrap;

    @media screen and (min-width: $screen-md-min) {
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  .column-form {
    width: auto;
    flex: 1 1 auto;
    order: 2;

    @media screen and (min-width: $screen-md-min) {
      padding-right: $grid-gutter-width;
    }
  }

  .column-summary {
    margin-bottom: 50px;
    width: auto;
    flex: 1 1 auto;
    order: 1;

    .highlight {
      color: $m-form-required-sign-color;
    }

    @media screen and (min-width: $screen-md-min) {
      flex: 0 0 370px;
      order: 3;
    }
  }

  .section-subheading {
    margin: 0 0 40px;
    font-size: 2.5rem;
    text-transform: uppercase;

    &:not(:first-of-type) {
      margin-top: 40px;

      @media screen and (min-width: $screen-md-min) {
        margin-top: 80px;
      }
    }

    &:after {
      @include line-ornament(50px, 3px, 15px 0 0);
    }

    &--aside {
      .back-to-menu {
        font-size: 1.125rem;
      }
    }
  }

  .summary-view {
    margin-bottom: 0;

    th,
    td {
      padding: $table-cell-padding;
      text-align: center;

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }

    th {
      font-size: 1rem;
    }
  }

  label,
  legend {
    font-size: 1rem;
  }

  .form-actions {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid $separator-color;
  }
}
