.m-teasers {
  &__list {
    @include list-unstyled;
    margin: 0 auto 50px;
    max-width: 1920px;
    display: flex;
    flex-wrap: wrap;
  }

  &__list-item {
    position: relative;
    flex: 1 1 50%;

    &:nth-child(n+7) {
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }

    @media screen and (min-width: $screen-xs-min) {
      flex-basis: 33%;
    }

    @media screen and (min-width: $screen-md-min) {
      flex-basis: 25%;

      @media screen and (max-width: $screen-md-max) {
        &:nth-child(n+9) {
          display: none;
        }
      }
    }

    @media screen and (min-width: $screen-lg-min) {
      flex-basis: 20%;
    }
  }


  &__item-image {
    width: 100%;
  }

  &__action {
    margin-bottom: 0;
    text-align: center;
  }

  &__ornament-row {
    display: flex;

    .m-teasers__ornament-image {
      display: none;
      width: 300px;

      @media (min-width: $screen-md-min) {
        display: block;
        margin-right: $grid-gutter-width;
        flex: 0 0 auto;
      }

      @media (min-width: $screen-lg-min) {
        margin-right: 80px;
        width: auto;
      }
    }
  }

  &__ornament-content {
    position: relative;
    flex-grow: 1;
    max-width: 100%;

    &.js-slider {
      @media (min-width: $screen-md-min) {
        max-width: 50vw;

        .m-indicators {
          position: absolute;
          top: 50%;
          right: ceil($grid-gutter-width / -1);
          transform: translateX(100%) translateY(-50%);
        }
      }

      @media (min-width: $screen-xlg-min) {
        max-width: 40vw;
      }
    }
  }
}
