// TODO: Theme settings, style adjustments

.m-fancy-boxes {
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $grid-gutter-width;

    @media (min-width: $screen-sm-min) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: $grid-gutter-width;

    @media (min-width: $screen-sm-min) {
      grid-column: 2;
    }

    &:nth-child(1) {
      padding-top: 90px;

      @media (min-width: $screen-sm-min) {
        grid-column: 1;
        grid-row: 1 / 3;
      }
    }

    &:nth-child(2) {
      .m-fancy-boxes__item-action {
        margin-bottom: 0;
      }

      @media (min-width: $screen-sm-min) {
        grid-row: 1;
        grid-column: 2;
      }
    }

    &:nth-child(3) {
      .m-fancy-boxes__item-action {
        margin-bottom: 0;
      }

      @media (min-width: $screen-sm-min) {
        grid-row: 2;
        grid-column: 2;
      }
    }

    &--has-aside {
      flex-direction: row;
    }
  }

  &__ribbon {
    @include position(absolute, 50px -80px null auto);
    width: 330px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--color-primary);
    font-size: 1.125rem;
    text-align: center;
    transform: rotate(45deg);

    &,
    &.m-heading {
      color: var(--color-text-on-primary-bg);
    }
  }

  &__picture {
    display: block;
    order: 2;

    &--left {
      margin: ($grid-gutter-width / -1) ceil($grid-gutter-width / 2) ($grid-gutter-width / -1) ($grid-gutter-width / -1);
      order: 0;
      flex-basis: 40%;
      flex-shrink: 0;

      .m-fancy-boxes__picture-img {
        @include object-fit(100% 50%, auto, 100%);
      }
    }

    &--right {
      margin: ($grid-gutter-width / -1) ($grid-gutter-width / -1) ($grid-gutter-width / -1) ceil($grid-gutter-width / 2);
      flex-basis: 40%;
      flex-shrink: 0;

      .m-fancy-boxes__picture-img {
        @include object-fit(0 50%, auto, 100%);
      }
    }

    &--bottom {
      margin: 0 ($grid-gutter-width / -1) ($grid-gutter-width / -1);

      .m-fancy-boxes__picture-img {
        @include object-fit(50% 0, auto, 100%);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 1;
    flex-grow: 1;
    align-self: center;
  }

  .m-page-subheader {
    + .m-fancy-boxes__item-action {
      margin-top: 28px;

      @media screen and (min-width: $grid-float-breakpoint) {
        margin-top: 49px;
      }
    }
  }
}
