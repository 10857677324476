.m-testimonials {
  &__list {
    margin: 0 floor($grid-gutter-width / -2) ($grid-gutter-height / -1);

    &:not(.splide__list) {
      display: flex;
      flex-wrap: wrap;

      &--list {
        display: block;
      }
    }
  }

  &__item {
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    margin-bottom: $grid-gutter-height;
    flex: 1 1 350px;
    outline: none;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex-basis: 50%;
    }

    .m-testimonials__list.splide__list & {
      flex: 1 0 auto;
    }
  }

  &__content {
    position: relative;
    margin-bottom: 15px;
    padding: 45px;
    font-size: 1rem;
    font-style: italic;

    &:after {
      @include position(absolute, 100% null null 50px);
      width: 0;
      height: 0;
      border-top: 20px solid;
      border-left: 20px solid transparent;
      content: "";
    }
  }

  &__author {
    margin-left: 90px;
    font-size: 1.125rem;

    &--regular {
      margin-left: 0;
      padding-top: 15px;
    }
  }

  &__indicators {
    margin-top: $grid-gutter-height;
  }

  &__annotation {
    margin-top: 15px;
    margin-left: 70px;
    padding-left: 20px;
    border-left: 4px solid rgba(#000, 0.3);
  }

  &__rating {
    margin-left: -0.2em;
    margin-right: -0.2em;
    line-height: 1;
  }

  &__row {
    @media screen and (min-width: $screen-md-min) {
      display: flex;
      margin-right: floor($grid-gutter-width / -2);
      margin-left: floor($grid-gutter-width / -2);
    }
  }

  &__col {
    @media screen and (min-width: $screen-md-min) {
      padding-right: floor($grid-gutter-width / 2);
      padding-left: floor($grid-gutter-width / 2);
      flex: 1 1 50%;
      max-width: 50%;
    }

    &--media {
      display: none;

      @media screen and (min-width: $screen-md-min) {
        display: flex;
        margin-top: -30px;
        padding-right: 40px;
        align-items: flex-end;
        overflow: hidden;
      }
    }
  }

  &--light {
    .m-testimonials {
      &__content {
        background-color: #fff;
        box-shadow: 5px 20px 15px rgba(#000, 0.1);

        &:after {
          border-top-color: #fff;
          border-right: 20px solid transparent;
          border-left: 20px solid transparent;
        }
      }

      &__media-img {
        &[src*=".png"] {
          filter: drop-shadow(20px 20px 15px rgba(#000, 0.2));
        }
      }

      &__author {
        color: #fff;
      }

      &__rating {
        color: #fff;
      }
    }
  }
}
